const DocMenuConfig = [
  {
    pages: [
      {
        heading: "Dashboard",
        route: "/dashboard",
        svgIcon: "media/icons/duotune/art/art002.svg",
        fontIcon: "bi-app-indicator",
      },
    ],
  },
  {
    heading: "Online Orders",
    route: "/crafted",
    pages: [
      /*{
        heading: "Unshipped FBM Package Labels To Be Printed",
        route: "/fbm-package-labels-to-be-printed",
        svgIcon: "media/icons/duotune/art/art002.svg",
        fontIcon: "bi-app-indicator",
      },*/
      {
        heading: "Create Package Labels",
        route: "/fbm-package-labels-to-be-printed",
        svgIcon: "media/icons/duotune/art/art002.svg",
        fontIcon: "bi-app-indicator",
      },
      {
        heading: "Unshipped FBM Orders",
        route: "/fbm-unshipped-orders",
        svgIcon: "media/icons/duotune/art/art002.svg",
        fontIcon: "bi-app-indicator",
      },
      {
        heading: "Shipped FBM Orders",
        route: "/fbm-shipped-orders",
        svgIcon: "media/icons/duotune/art/art002.svg",
        fontIcon: "bi-app-indicator",
      },
      {
        heading: "Cancelled FBM Orders",
        route: "/fbm-cancelled-orders",
        svgIcon: "media/icons/duotune/art/art002.svg",
        fontIcon: "bi-app-indicator",
      },
    ],
  },
  {
    heading: "UPS",
    route: "/crafted",
    pages: [
      {
        heading: "Get Shipment",
        route: "/shipments",
        svgIcon: "media/icons/duotune/art/art002.svg",
        fontIcon: "bi-app-indicator",
      },
      {
        heading: "Get Shipping Rates",
        route: "/shippingrate",
        svgIcon: "media/icons/duotune/art/art002.svg",
        fontIcon: "bi-app-indicator",
      },
      {
        heading: "Verify Address",
        route: "/verifyaddress",
        svgIcon: "media/icons/duotune/art/art002.svg",
        fontIcon: "bi-app-indicator",
      },
    ],
  },
  {
    heading: "Shipping",
    route: "/crafted",
    pages: [
      {
        heading: "Pick List",
        route: "/picklist",
        svgIcon: "media/icons/duotune/art/art002.svg",
        fontIcon: "bi-app-indicator",
      },
    ],
  },
  {
    heading: "Support",
    route: "/crafted",
    pages: [
      {
        heading: "Contact Support",
        route: "/contact-support",
        svgIcon: "media/icons/duotune/art/art002.svg",
        fontIcon: "bi-app-indicator",
      },
    ],
  },
];

export default DocMenuConfig;
