
import { defineComponent, computed } from "vue";
import KTUserMenu from "@/layout/header/partials/UserMenu.vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "topbar",
  components: {
    KTUserMenu,
  },
  setup() {
    const store = useStore();

    const userAvator = computed(() => {
      return store.getters.getUserAvator;
    });

    return {
      userAvator,
    };
  },
});
