const DocMenuConfig = [
  {
    pages: [
      {
        heading: "Dashboard",
        route: "/dashboard",
        svgIcon: "media/icons/duotune/art/art002.svg",
        fontIcon: "bi-app-indicator",
      },
    ],
  },
  {
    heading: "Super Admin",
    route: "/crafted",
    pages: [
      {
        sectionTitle: "Users",
        route: "/pages",
        svgIcon: "media/icons/duotune/general/gen022.svg",
        fontIcon: "bi-archive",
        sub: [
          {
            heading: "Application Users",
            route: "/super-admin/applicationuserlisting",
          },
        ],
      },
    ],
  },
];

export default DocMenuConfig;
