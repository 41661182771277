
import { defineComponent, onMounted, computed, ref } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { ScrollComponent } from "@/assets/ts/components/_ScrollComponent";
import { MenuComponent } from "@/assets/ts/components/MenuComponent";
import { asideMenuIcons } from "@/core/helpers/config";
import SnappyDefaultAsideMenuConfig from "@/core/config/SnappyDefaultAsideMenuConfig";
import SnappySuperAdminAsideMenuConfig from "@/core/config/SnappySuperAdminAsideMenuConfig";

export default defineComponent({
  name: "kt-menu",
  components: {},
  setup() {
    const route = useRoute();
    const scrollElRef = ref<null | HTMLElement>(null);
    const store = useStore();

    onMounted(() => {
      ScrollComponent.reinitialization();
      MenuComponent.reinitialization();
      if (scrollElRef.value) {
        scrollElRef.value.scrollTop = 0;
      }
    });

    const hasActiveChildren = (match) => {
      return route.path.indexOf(match) !== -1;
    };

    const Navigation = computed(() => {
      if (store.getters.currentUserRole === "SuperAdmin") {
        return SnappySuperAdminAsideMenuConfig;
      }
      return SnappyDefaultAsideMenuConfig;
    });

    return {
      hasActiveChildren,
      asideMenuIcons,
      Navigation,
    };
  },
});
