
import { defineComponent, onMounted, computed } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { MenuComponent } from "@/assets/ts/components";
import SnappyDefaultHeaderMenuConfig from "@/core/config/SnappyDefaultHeaderMenuConfig";
import { headerMenuIcons } from "@/core/helpers/config";
import { version } from "@/core/helpers/documentation";

export default defineComponent({
  name: "KTMenu",
  components: {},
  setup() {
    const route = useRoute();
    const store = useStore();

    const hasActiveChildren = (match) => {
      return route.path.indexOf(match) !== -1;
    };

    onMounted(() => {
      MenuComponent.reinitialization();
    });

    const Navigation = computed(() => {
      if (store.getters.currentUserRole === "SuperAdmin") {
        return SnappyDefaultHeaderMenuConfig;
      }
      return SnappyDefaultHeaderMenuConfig;
    });

    return {
      hasActiveChildren,
      headerMenuIcons,
      SnappyDefaultHeaderMenuConfig,
      Navigation,
      version,
    };
  },
});
